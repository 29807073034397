import "./App.css";
import DashBoard from "./Views/Main/DashBoard";

function App() {
  return (
    <DashBoard></DashBoard>
  );
}

export default App;
